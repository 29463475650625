import React from "react"

export const Privacy = () => {
    return (
        <div>
            <p>
                Gizlilik Politikamız Bu Gizlilik Politikası, USTK Digital
                Solutions (“Şirketimiz”, “biz” veya “bize”) tarafından sunulan
                hizmetlerle ilgili olarak, kişisel bilgilerinizin nasıl
                toplandığı, kullanıldığı, saklandığı ve korunduğu hakkında bilgi
                vermektedir. Şirketimize ait hizmetleri kullanarak, bu
                politikada belirtilen uygulamaları kabul etmiş sayılırsınız. 1.
                Topladığımız Bilgiler Hizmetlerimizi sunmak ve geliştirmek için
                şu tür kişisel bilgileri toplayabiliriz: Kışisel Bilgiler: Ad,
                soyad, e-posta adresi, telefon numarası, şirket bilgileri gibi
                bilgiler. Teknik Bilgiler: IP adresi, cihaz türü, tarayıcı
                bilgileri, çerezler ve oturum bilgileri. Hizmet Kullanım
                Bilgileri: Hizmetlerimizi kullanım şeklinizle ilgili bilgiler
                (ziyaret ettiğiniz sayfalar, tıkladığınız bağlantılar vb.). 2.
                Bilgilerin Kullanım Amacı Topladığımız kişisel bilgileri
                aşağıdaki amaçlarla kullanırız: Hizmetlerimizi sunmak,
                iyileştirmek ve kişiselleştirmek. Müşteri hizmetleri ve destek
                sağlamak. Pazarlama iletişimleri göndermek (onayınızla). Yasal
                zorunluluklara uymak ve dolandırıcılığı önlemek. 3. Bilgi
                Paylaşımı Kişisel bilgileriniz, aşağıdaki durumlar dışında
                üçüncü taraflarla paylaşılmaz: Hizmet Sağlayıcıları:
                Bilgileriniz, hizmetlerimizi sunmamıza yardımcı olan çözüm
                ortaklarımızla paylaşılabilir. Yasal Gereklilikler: Yasal
                zorunluluklar doğrultusunda bilgileriniz yetkili makamlarla
                paylaşılabilir. Birleşme veya Satın Alma: Şirketimizin
                birleşmesi, satın alınması veya varlıklarının satılması
                durumunda bilgileriniz aktarılabilir. 4. Bilgi Güvenliği Kişisel
                bilgilerinizin korunması için endüstri standartlarına uygun
                önlemler alırız. Ancak, internet üzerinden hiçbir veri
                iletiminin tamamen güvenli olduğu garanti edilemez. 5. Çerezler
                ve Benzer Teknolojiler Web sitemiz, deneyiminizi iyileştirmek ve
                trafiği analiz etmek için çerezler kullanabilir. Tarayıcı
                ayarlarınızı kullanarak çerezleri reddetme veya silme
                seçenekleriniz bulunmaktadır. 6. Müşteri Hakları Kişisel
                verilerinize erişim, düzeltme, silme veya taşıma hakkına
                sahipsiniz. Bu hakları kullanmak için bizimle aşağıdaki iletişim
                bilgilerinden ulaşabilirsiniz. 7. Gizlilik Politikası
                Değişiklikleri Bu Gizlilik Politikası zaman zaman
                güncellenebilir. Güncellemeler bu sayfada yayınlanacak ve
                gerekiyorsa e-posta yoluyla bildirilecektir. 8. İletişim
                Gizlilik politikamızla ilgili sorularınız veya talepleriniz için
                bizimle iletişime geçebilirsiniz: E-posta:
                ustkdigitalsolutions@gmail.com
            </p>
        </div>
    )
}
