import "./App.css"
import { Home } from "./screens/Home/Home"

function App() {
    return (
        <div className="App">
            <Home />
        </div>
    )
}

export default App
